import GlobalContext from "./GlobalContext";
import { useState, useEffect } from "react";
import axios from "../utils/axios";
import { Form, message, notification } from "antd";
import classes from "../pages/Home/Home.module.css";
import SimpleCrypto from "simple-crypto-js";
const AppContext = (props) => {
  const [brandProperties, setBrandProperties] = useState();
  const [couvertureErr, setCouverturesErr] = useState(false);
  const [entreprise, setEntreprise] = useState();
  const [cotisation, setCotisation] = useState(
    JSON.parse(sessionStorage.getItem("globalData"))?.type_fractionnement
      ? JSON.parse(sessionStorage.getItem("globalData"))
          ?.type_fractionnement === "Mensuel"
        ? "cotisationMensuel"
        : "cotisationAnnuel"
      : "cotisationMensuel"
  );
  const [validRef, setValidRef] = useState(false);
  const [userData, setUserData] = useState(null);
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const id = searchParams?.get("id");

  const [subscribeEnabled, setSubscribeEnabled] = useState(id ? false : true);
  const [form] = Form.useForm();

  const [current, setCurrent] = useState(
    Number(sessionStorage.getItem("current")) || 1
  );
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : ""
  );
  const [methodePaimenet, setMethodePaiement] = useState(
    JSON.parse(sessionStorage.getItem("globalData")) &&
      JSON.parse(sessionStorage.getItem("globalData"))["methodePaiement"]
      ? JSON.parse(sessionStorage.getItem("globalData"))["methodePaiement"]
      : "Mandat SEPA"
  );
  const [assurerProches, setAssurerProches] = useState(
    JSON.parse(sessionStorage.getItem("globalData")) &&
      JSON.parse(sessionStorage.getItem("globalData"))["assurer"]
      ? JSON.parse(sessionStorage.getItem("globalData"))["assurer"]
      : "Moi seulement"
  );
  const [garantieOptions, setGarantieOptions] = useState(sessionStorage.getItem("garantieOptions") ?
    JSON.parse(sessionStorage.getItem("garantieOptions")) : {
      conséquences_accidents_corporels: true,
      accidents_corporels_conséquences_graves: true,
      indemnité_journalière_cas_hospitalisation: true,
      versement_capital_forfaitaire: true,
      subi_accident_cinq_dernières_années: false,
      hospitalisation_programmée: false,
      mesure_curatelle_tutelle: false,
      papiers_sans_aide_ext: false,
      politiquement_exposée: false,
      conséquences_accidents_corporels_conjoint: true,
      accidents_corporels_conséquences_graves_conjoint: true,
      indemnité_journalière_cas_hospitalisation_conjoint: true,
      versement_capital_forfaitaire_conjoint: true,
      subi_accident_cinq_dernières_années_conjoint: false,
      hospitalisation_programmée_conjoint: false,
      conséquences_accidents_corporels_enfant: true,
      accidents_corporels_conséquences_graves_enfant: true,
      indemnité_journalière_cas_hospitalisation_enfant: true,
      versement_capital_forfaitaire_enfant: true,
      subi_accident_cinq_dernières_années_enfant: false,
      hospitalisation_programmée_enfant: false,
    }
  );
  const [tarifs, setTarifs] = useState([]);
  const [file, setFile] = useState();
  const [idOpp, setIdOpp] = useState("");

  const partnership_id = searchParams?.get("partnership_id");

  const [garantie, setGarantie] = useState(
    JSON.parse(sessionStorage.getItem("globalData"))
      ? JSON.parse(sessionStorage.getItem("globalData"))["garantie"]
      : ""
  );

  useEffect(() => {
    if (id) {
      axios
        .get(
          `${
            import.meta.env.VITE_API_URL_AS
          }/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          const simpleCrypto = new SimpleCrypto(
            import.meta.env.VITE_API_ENCRYPTION_SECRET_KEY
          );
          const decryptedData = simpleCrypto?.decrypt(
            res.data?.data.replaceAll(" ", "+")
          );
          const { allData, page ,garantiesComplementaires} = decryptedData;
          setGlobalData(allData);
          setGarantie(allData["garantie"]);
          setAssurerProches(allData["assurer"]);
          form.setFieldsValue(allData);
          setCurrent(page);
          setGarantieOptions(garantiesComplementaires);
          sessionStorage.setItem("globalData", JSON.stringify(allData));
          sessionStorage.setItem("garantieOptions", JSON.stringify(garantiesComplementaires));
          setValidRef(true);
        });
    }
    if (partnership_id) {
      sessionStorage.setItem("api_key", partnership_id);
      let config = {
        headers: {
          Authorization: `Bearer ${partnership_id}`,
        },
      };
      axios
        .post(`${import.meta.env.VITE_API_GEOPROD_URL}/login`, {}, config)
        .then((response) => {
          sessionStorage.setItem("token", response.data.Acces_Token);
          setUserData(response.data);
          axios
            .get(
              `${import.meta.env.VITE_API_GEOPROD_URL}/organismes/get_by_id/${
                response.data.user.entreprise_id
              }`,
              {
                headers: {
                  idSession: response?.data?.Acces_Token,
                },
              }
            )
            .then((res) => {
              setEntreprise({
                nomEntreprise: res?.data?.nom,
                orias: res?.data?.orias,
                rcs: res?.data?.rcs,
                adresseEntreprise: res?.data?.adresse,
              });

              axios
                .get(
                  `${
                    import.meta.env.VITE_API_GEOPROD_URL
                  }/organismes/get_by_id/${res?.data?.organisme_master}`,
                  {
                    headers: {
                      idSession: response?.data?.Acces_Token,
                    },
                  }
                )
                .then((res) => {
                  setBrandProperties({
                    logo: res.data.logo,
                    principalColor: "#024FA8",
                    secondColor: "#FF422C",
                  });
                });
            });
        })
        .catch((err) => console.log(err));
    } else {
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  }, []);

  const handleAddChild = () => {
    if (form.getFieldValue("tab_enfants").length < 5) {
      const newChildItem = {
        nom_enfant: "",
        prenom_enfant: "",
        lieu_naissance_enfant: "",
        date_naissance_enfant: null,
      };

      form.setFieldValue("tab_enfants", [
        ...form.getFieldValue("tab_enfants"),
        newChildItem,
      ]);
    } else {
      notification.warning({
        className: classes.warning,
        message: "Attention",
        description: (
          <div>
            Nous vous informons que le nombre maximum d'enfants à assurer est de
            5. <br />
            Si vous avez plus de 5 enfants à assurer, veuillez nous contacter
            directement pour trouver une solution adaptée à vos besoins.
          </div>
        ),
      });
    }
  };
  return (
    <GlobalContext.Provider
      value={{
        brandProperties,
        garantie,
        setGarantie,
        couvertureErr,
        setCouverturesErr,
        globalData,
        setGlobalData,
        methodePaimenet,
        setMethodePaiement,
        tarifs,
        setTarifs,
        assurerProches,
        setAssurerProches,
        handleAddChild,
        file,
        setFile,

        id,
        idOpp,
        setIdOpp,
        userData,
        entreprise,
        validRef,
        setValidRef,
        current,
        setCurrent,
        form,
        cotisation,
        setCotisation,
        subscribeEnabled,
        setSubscribeEnabled,
        partnership_id,
        garantieOptions, setGarantieOptions
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
