import { Col, Row, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  Blessure,
  Hospitalisation,
  PackGaranties,
} from "../../components/Icons/Icons";
import CardComponent from "./CardComponent/CardComponent";
import classes from "./Couvertures.module.css";
import GlobalContext from "../../contexts/GlobalContext";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import FormHeader from "../../components/FormHeader/FormHeader";
import axios from "../../utils/axios";
import dayjs from "dayjs";
const Couvertures = (props) => {
  const { form } = props;
  const {
    garantie,
    couvertureErr,
    globalData,
    tarifs,
    setTarifs,
    setGarantie,
    partnership_id,
  } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const cards = [
    {
      name: "BLESSURE",
      description:
        "BLESSUREO a pour objet de garantir le versement d’une indemnité journalière forfaitaire en cas d’hospitalisation par suite de maladie ou d’accident et d’une indemnité forfaitaire en cas de blessures résultant d’un accident de l’adhérent, de son conjoint ou d’un ou plusieurs enfant(s) à charge.",
      cardImage: <Blessure />,
    },
    {
      name: "IJH SOLO",
      description:
        "HOSPINEO a pour objet de garantir le versement d’indemnités journalières forfaitaires en cas d’hospitalisation de l'adhérent, de son conjoint assuré ou d’un enfant à charge assuré, par suite de maladie ou d’accident.",
      cardImage: <Hospitalisation />,
    },

    {
      name: "BLESSURE DECES",
      description:
        "PAISIBLEO a pour objet de garantir le versement d’une indemnité journalière forfaitaire en cas d’hospitalisation par suite de maladie ou d’accident et d’une indemnité forfaitaire en cas de blessures résultant d’un accident de l’adhérent, de son conjoint ou d’un ou plusieurs enfant(s) à charge, le versement d’un capital garanti en cas de décès ou en cas de PTIA résultant d’un accident pour l’adhérent ou son conjoint.",
      cardImage: <PackGaranties />,
    },
  ];

  useEffect(() => {
    if (partnership_id) {
      
      var customerInfoTarif = [
        {
          date_naissance:
            globalData &&
            globalData["date_naissance"],
          assure: "PRINCIPALE",
        },
      ];
      if (globalData?.assurer === "Moi et mes proches") {
        customerInfoTarif = [
          ...customerInfoTarif,
          {
            date_naissance: globalData["date_naissance_conjoint"],
            assure: "CONJOINT",
          },
        ];
      }

      if (globalData?.tab_enfants?.length >= 1) {
        globalData?.tab_enfants?.forEach((element, index) => {
          customerInfoTarif = [
            ...customerInfoTarif,
            {
              date_naissance: element?.date_naissance_enfant
                ? element?.date_naissance_enfant
                : "",

              assure: "ENFANT",
            },
          ];
        });
      }

      const data = { customers_informations: customerInfoTarif,  effective_date : dayjs(globalData?.dateEffet, "DD/MM/YYYY").format("DD/MM/YYYY")};
      let config = {
        headers: {
          apiKey: partnership_id,
        },
      };
      axios
        .post(
          `${import.meta.env.VITE_API_URL_AS}/tarification_ijh/get_tarif`,
          data,
          config
        )
        .then((res) => {
          if (res?.data?.error === false) {
            setIsLoading(false);
            let newTarifs = [{}, {}, {}];

            Object.keys(res?.data?.message)?.map((pack) => {
              const card = cards.find((card) => card.name === pack);
              newTarifs?.splice(
                pack === "IJH SOLO"
                  ? 1
                  : pack === "BLESSURE"
                  ? 2
                  : pack === "BLESSURE DECES"
                  ? 3
                  : 4,
                0,
                {
                  name:
                    pack === "IJH SOLO"
                      ? "HOSPINEO"
                      : pack === "BLESSURE"
                      ? "BLESSUREO"
                      : "PAISIBLEO",
                  tarif:
                    res?.data?.message[pack]?.TOTAL?.MENSUEL
                      ?.tarif_public_total,
                  description: card?.description,
                  cardImage: card?.cardImage,
                }
              );
              setTarifs(
                newTarifs?.filter((item) => Object.keys(item).length > 0)
              );
            });
            if (garantie && garantie?.length !== 0) {
              let newCard = {
                name: garantie?.name,
                tarif:
                  res?.data?.message[
                    garantie?.name === "HOSPINEO"
                      ? "IJH SOLO"
                      : garantie?.name === "BLESSUREO"
                      ? "BLESSURE"
                      : "BLESSURE DECES"
                  ]?.TOTAL?.MENSUEL?.tarif_public_total,
              };
              setGarantie(newCard);
            }
            
          }
        })
        .catch((e) => {
          console.log(e);
          setTarifs([]);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  }, []);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      {!isLoading && tarifs.length > 0 ? (
        <Col className={classes.container}>
          {couvertureErr && (
            <Row className={classes.rowErreur}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.3869 18.5733L17.9202 5.13334C16.9169 3.32501 15.5286 2.33334 14.0002 2.33334C12.4719 2.33334 11.0836 3.32501 10.0802 5.13334L2.61355 18.5733C1.66855 20.2883 1.56355 21.9333 2.32188 23.2283C3.08022 24.5233 4.57355 25.235 6.53355 25.235H21.4669C23.4269 25.235 24.9202 24.5233 25.6786 23.2283C26.4369 21.9333 26.3319 20.2767 25.3869 18.5733ZM13.1252 10.5C13.1252 10.0217 13.5219 9.62501 14.0002 9.62501C14.4786 9.62501 14.8752 10.0217 14.8752 10.5V16.3333C14.8752 16.8117 14.4786 17.2083 14.0002 17.2083C13.5219 17.2083 13.1252 16.8117 13.1252 16.3333V10.5ZM14.8286 20.6617C14.7702 20.7083 14.7119 20.755 14.6536 20.8017C14.5836 20.8483 14.5136 20.8833 14.4436 20.9067C14.3736 20.9417 14.3035 20.965 14.2219 20.9767C14.1519 20.9883 14.0702 21 14.0002 21C13.9302 21 13.8486 20.9883 13.7669 20.9767C13.6969 20.965 13.6269 20.9417 13.5569 20.9067C13.4869 20.8833 13.4169 20.8483 13.3469 20.8017C13.2885 20.755 13.2302 20.7083 13.1719 20.6617C12.9619 20.44 12.8336 20.1367 12.8336 19.8333C12.8336 19.53 12.9619 19.2267 13.1719 19.005C13.2302 18.9583 13.2885 18.9117 13.3469 18.865C13.4169 18.8183 13.4869 18.7833 13.5569 18.76C13.6269 18.725 13.6969 18.7017 13.7669 18.69C13.9186 18.655 14.0819 18.655 14.2219 18.69C14.3035 18.7017 14.3736 18.725 14.4436 18.76C14.5136 18.7833 14.5836 18.8183 14.6536 18.865C14.7119 18.9117 14.7702 18.9583 14.8286 19.005C15.0386 19.2267 15.1669 19.53 15.1669 19.8333C15.1669 20.1367 15.0386 20.44 14.8286 20.6617Z"
                  fill="#FF422C"
                />
              </svg>
              <span className={classes.erreur}>
                Vous devez choisir une garantie.
              </span>
            </Row>
          )}
          <FormHeader name="Les garanties souhaitées pour votre assurance" />
          <Row gutter={[16]} className={classes.card}>
            {tarifs?.map((card, index) => (
              <CardComponent card={card} key={index} form={form} />
            ))}
          </Row>
          {/* <Row className={classes.nb}>
            <Col xs={24} sm={24} lg={6} md={24} >
              <svg
                width="78"
                height="104"
                viewBox="0 0 78 104"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.8789 25.5263C23.1821 26.2834 20.3686 24.6777 19.6296 21.9624L15.3639 6.47975C14.6119 3.76442 16.2067 0.93159 18.9035 0.187484C21.6003 -0.569677 24.4138 1.03603 25.1528 3.75136L29.4185 19.234C30.1705 21.9363 28.5757 24.7691 25.8789 25.5263Z"
                  fill="#2D4351"
                />
                <path
                  d="M47.0645 102.691C42.5525 103.958 37.8331 101.268 36.5754 96.7124L34.1509 87.9137C32.8933 83.3708 35.5641 78.6189 40.0891 77.3526C44.601 76.0863 49.3204 78.7756 50.5781 83.3316L53.0026 92.1303C54.2603 96.6733 51.5894 101.425 47.0645 102.691Z"
                  fill="#2D4351"
                />
                <path
                  d="M68.2898 67.9142L58.1378 31.0614C53.7815 15.2654 37.3802 5.91842 21.692 10.3047C6.00387 14.691 -3.27937 31.205 1.07701 47.0009L11.2289 83.8538C12.1754 87.2741 12.4347 90.8379 12.0198 94.3626L11.0474 102.535L44.5371 93.1877L78.0009 83.8146L72.9833 77.3135C70.831 74.5198 69.2233 71.3215 68.2898 67.9142Z"
                  fill={brandProperties?.principalColor}
                />
                <path
                  d="M23.5205 48.8938C21.5108 49.4552 19.7605 49.1288 18.2565 47.9147C16.7525 46.7007 15.7023 44.9905 15.0929 42.7974C14.4836 40.565 14.4965 38.5416 15.1578 36.727C15.819 34.9124 17.1674 33.7114 19.203 33.1501C21.2256 32.5887 22.9889 32.9021 24.4929 34.1161C25.9969 35.3171 27.06 37.0273 27.6694 39.2465C28.2918 41.505 28.2658 43.5415 27.5787 45.356C26.8915 47.1445 25.5431 48.3325 23.5205 48.8938ZM19.9809 35.8915C19.1511 36.1265 18.6714 36.6617 18.5547 37.4842C18.438 38.3197 18.6325 39.6773 19.1511 41.5833C19.6697 43.4501 20.1884 44.7164 20.7199 45.3822C21.2515 46.0479 21.9257 46.2699 22.7425 46.0349C23.5464 45.813 24.0132 45.2777 24.1558 44.4292C24.2854 43.5806 24.1169 42.2752 23.6242 40.4998C23.0797 38.5155 22.5351 37.1839 22.0035 36.5312C21.4719 35.8785 20.7977 35.6696 19.9809 35.8915ZM25.6727 64.5592L34.0873 29.2207L37.4713 28.2808L29.0308 63.6193L25.6727 64.5592ZM44.0447 59.6638C42.0221 60.2251 40.2718 59.8988 38.7678 58.6847C37.2638 57.4706 36.2136 55.7474 35.6043 53.5412C34.9949 51.3089 35.0078 49.2985 35.6691 47.4839C36.3303 45.6694 37.6787 44.4814 39.7273 43.907C41.7499 43.3457 43.5132 43.672 45.0172 44.8861C46.5211 46.1002 47.5843 47.8103 48.1937 50.0034C48.816 52.2619 48.7901 54.2984 48.1029 56.0999C47.4028 57.9145 46.0544 59.1024 44.0447 59.6638ZM40.5052 46.6615C39.7532 46.8704 39.2735 47.3534 39.092 48.1105C38.8975 48.8677 39.092 50.2776 39.6624 52.3663C40.181 54.2462 40.7126 55.5255 41.2572 56.1782C41.8017 56.8309 42.4759 57.0398 43.2798 56.8179C44.0836 56.596 44.5374 56.0607 44.6801 55.2122C44.8097 54.3636 44.6412 53.0582 44.1485 51.3089C43.6039 49.3246 43.0594 47.9931 42.5278 47.3273C41.9962 46.6615 41.322 46.4396 40.5052 46.6615Z"
                  fill="white"
                />
                <path
                  d="M40.9699 68.98L9.54883 77.756L10.4638 81.077L41.8849 72.301L40.9699 68.98Z"
                  fill="white"
                />
                <path
                  d="M66.6098 61.8181L47.2949 67.2128L48.2099 70.5338L67.5248 65.139L66.6098 61.8181Z"
                  fill="white"
                />
              </svg>
            </Col>

            <Col xs={24} sm={24} lg={16} md={24}>
              <p
                className={classes.choice}
                style={{ color: brandProperties?.principalColor }}
              >
                En choisissant{" "}
                <span
                  className={classes.trois}
                  style={{ color: brandProperties?.principalColor }}
                >
                  les trois garanties
                </span>
                , vous bénéficierez d'un package avec{" "}
                <span
                  className={classes.trois}
                  style={{ color: brandProperties?.principalColor }}
                >
                  une réduction de 10%
                </span>
              </p>
            </Col>
          </Row> */}
        </Col>
      ) : (
        <CustomLoader text="Nous calculons votre prime..." />
      )}
    </div>
  );
};

export default Couvertures;
