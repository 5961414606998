import React, { useContext, useEffect, useState } from "react";
import InputComponent from "../../components/InputComponent/InputComponent";
import classes from "./InformationsSouscripteur.module.css";
import { PhoneOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Form } from "antd";
import ChildDetails from "./ChildDetails/ChildDetails";
import GlobalContext from "../../contexts/GlobalContext";
import axios from "../../utils/axios";
import FormHeader from "../../components/FormHeader/FormHeader";
import { IconAdd } from "../../components/Icons/Icons";
import dayjs from "dayjs";
import InformationsConjoint from "./InformationsConjoint/InformationsConjoint";
import listPays from "../../constants/listPays.json";

const InformationsSouscripteur = (props) => {
  const { form } = props;
  const {
    handleAddChild,
    assurerProches,
    setAssurerProches,
    setGlobalData,
    globalData,
  } = useContext(GlobalContext);
  const [villes, setVilles] = useState([]);
  const pays_options = Object.entries(listPays)
    .map(([code, name]) => ({
      value: name,
      key: name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const optionsRegime = [
    { label: "Sécurité sociale", value: "Sécurité sociale" },
    { label: "Travailleurs non salariés", value: "Travailleurs non salariés" },
    { label: "Agricole salarié", value: "Agricole salarié" },
    { label: "Agricole exploitant", value: "Agricole exploitant" },
    { label: "Alsace moselle", value: "Alsace moselle" },
  ];
  const getYears = (years) => {
    const effetDate = dayjs()
      .add(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const yearEffet = dayjs(effetDate).year();
    return yearEffet - years;
  };
  const getVilles = (code_postal) => {
    if (code_postal.length > 4) {
      const URL = import.meta.env.VITE_API_URL_AS;
      let ville_names = [];

      axios
        .post(
          `${URL}/groupe_villes/get_villes_by_code_postal`,
          {
            postal_code: code_postal,
          },
          {
            headers: {
              idSession: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          res.data.villes.forEach((ville) => {
            let new_ville = {
              value: ville?.nom_comm,
              label: ville?.nom_comm,
            };
            if (!ville_names.find((val) => val.label == ville?.nom_comm)) {
              ville_names.push(new_ville);
            }
          });
          setVilles(ville_names);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            _ville: ville_names[0]?.value,
          });
        })
        .catch((err) => {
          setVilles([]);

          form.setFieldsValue({
            ...form.getFieldsValue(),
            _ville: "",
          });
          console.error(err);
        });
    }
  };
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <FormHeader name="Informations du souscripteur" />
      <Row gutter={24}>
        <InputComponent
          name="civilite"
          messageRemplissage="Veuillez remplir ce champ."
          label="Civilité"
          inputType="select"
          required="true"
          options={[
            {
              value: "Mme",
              label: "Mme.",
            },
            {
              value: "Mr",
              label: "M.",
            },
          ]}
          collg={12}
          colMd={12}
          colxs={24}
          className={classes.label}
        />
        <InputComponent
          name="nom"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={
            new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
          }
          messageVerification="Veuillez vérifier ce champ."
          label="Nom"
          size="large"
          inputType="input"
          className={classes.label}
          collg={12}
          colMd={12}
          colxs={24}
        />
        <InputComponent
          name="prenom"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={
            new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
          }
          messageVerification="Veuillez vérifier ce champ."
          label="Prénom"
          size="large"
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />

        <InputComponent
          name="_adresse"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Adresse"
          size="large"
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />

        <InputComponent
          name="_code_postal"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Code Postal"
          size="large"
          messageVerification="Veuillez vérifier ce champ."
          pattern={new RegExp(/^.{5,}$/)}
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
          onBlur={getVilles}
          maxLength={5}
          onchange={(e) => {
            form.setFieldsValue({
              _code_postal: e.target.value.replace(/[^0-9]/g, ""),
            });
            form.validateFields(["_code_postal"]);
          }}
        />

        <InputComponent
          name="_ville"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Ville"
          size="large"
          className={classes.label}
          inputType="select"
          collg={12}
          colMd={12}
          colxs={24}
          options={villes}
        />

        <InputComponent
          name="pays"
          required={false}
          messageRemplissage="Veuillez remplir ce champ."
          label="Pays"
          size="large"
          className={classes.label}
          inputType="input"
          collg={24}
          colMd={24}
          colxs={24}
          placeholder="France"
          defaultValue="France"
        />
        <InputComponent
          name="lieuNaissance"
          messageRemplissage="Veuillez remplir ce champ."
          label="Pays de naissance"
          inputType="select"
          required="true"
          options={pays_options}
          collg={24}
          colMd={24}
          colxs={24}
          className={classes.label}
          showSearch={true}
        />
        <InputComponent
          name="date_naissance"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Date de naissance"
          size="large"
          className={classes.label}
          messageVerificationDate={
            "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
          }
          inputType="date"
          minDate={18}
          maxDate={65}
          collg={24}
          colMd={24}
          colxs={24}
        />
        <InputComponent
          form={form}
          name="num_sec_sociale"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          messageVerification="Veuillez vérifier ce champ."
          label="N° sécurité sociale"
          size="large"
          inputType="input"
          className={classes.label}
          collg={12}
          colMd={12}
          colxs={24}
        />
        <InputComponent
          name="regime_obligatoire"
          label="Régime Obligatoire"
          messageRemplissage="Veuillez remplir ce champ."
          required="true"
          inputType="select"
          options={optionsRegime}
          collg={12}
          colMd={12}
          colxs={24}
          className={classes.label}
        />
        <InputComponent
          name="souscripteur_email"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/)}
          label="Adresse e-mail "
          size="large"
          className={classes.label}
          // type="email"
          messageVerification="Veuillez vérifier ce champ."
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />
        <InputComponent
          form={form}
          name="téléphone"
          namePrefix="prefixTel"
          required={true}
          pattern={new RegExp(/^0?[1234567]\d{8}$/)}
          messageRemplissage="Veuillez remplir ce champ."
          messageVerification="Veuillez vérifier ce champ."
          label="Numéro de téléphone"
          size="large"
          className={classes.label}
          addonAfter={<PhoneOutlined style={{ color: "black" }} />}
          inputType="phone"
          collg={12}
          colMd={12}
          colxs={24}
          defaultValue={"+33"}
          handlePrefixChange={(e) => {
            form.validateFields(["téléphone"]);
            form.setFieldsValue({
              prefixTel: e.target.value.replace(/[^+0-9]/g, ""),
            });
            form.validateFields(["prefixTel"]);
          }}
          onchange={(e) => {
            form.setFieldsValue({
              téléphone: e.target.value.replace(/[^0-9]/g, ""),
            });
            form.validateFields(["téléphone"]);
          }}
        />
      </Row>
      <FormHeader name="Qui souhaitez-vous assurer ?" />

      <Row gutter={6} justify="space-around">
        <Col xs={24} sm={12} lg={6} style={{ marginBottom: "1rem" }}>
          <button
            type="button"
            onClick={() => {
              setAssurerProches("Moi et mes proches");
              setGlobalData({
                ...globalData,
                assurer: "Moi et mes proches",
              });
            }}
            className={classes.buttonsAdd}
            style={{
              backgroundColor: "#F2FAFF",
              border: "2px solid #D2EEFF",
            }}
            id="BTN_ajouter_conjoint(e)"
          >
            <IconAdd />
            Un(e) conjoint(e)
          </button>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <button
            onClick={handleAddChild}
            type="button"
            className={classes.buttonsAdd}
            style={{
              backgroundColor: "#C1E5FE",
              border: "2px solid #9DD7FF",
            }}
            id="BTN_ajouter_enfant"
          >
            <IconAdd />
            Un enfant
          </button>
        </Col>
      </Row>

      {assurerProches === "Moi et mes proches" && (
        <InformationsConjoint form={form} optionsRegime={optionsRegime} />
      )}

      <Form.List name="tab_enfants">
        {(fields, { add, remove }) => (
          <>
            <Row gutter={24} justify="space-between">
              {fields?.map(({ key, name, ...restField }) => (
                <ChildDetails
                  remove={remove}
                  name={name}
                  restField={restField}
                  key={key}
                  form={form}
                  optionsRegime={optionsRegime}
                />
              ))}{" "}
            </Row>
          </>
        )}
      </Form.List>

      <FormHeader name="Date d’effet souhaitée du contrat  ?" />
      <Row
        style={{ display: "flex", justifyContent: "center" }}
        className="starClass"
      >
        <InputComponent inputType="date" name="dateEffet" collg={11} />
      </Row>
    </div>
  );
};

export default InformationsSouscripteur;
