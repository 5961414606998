import React from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";

import classes from "./InputComponent.module.css";
import dayjs from "dayjs";
import moment from "moment";

const { Search } = Input;

const InputComponent = (props) => {
  const {
    required,
    messageRemplissage,
    pattern,
    label,
    name,
    type,
    messageVerification,
    size,
    className,
    controls,
    prefix,
    placeholder,
    addonAfter,
    addonBefore,
    inputType,
    namePrefix,
    options,
    onBlur,
    form,
    defaultValue,
    radioValues,
    disabledDate,
    messageVerificationDate,
    minVal,
    maxVal,
    maxDate,
    minDate,
    collg,
    colmd,
    colsm,
    colxs,
    restField,
    checkboxValues,
    onSelect,
    onchange,
    val,
    maxLength,
    handlePrefixChange,
    value,
    showSearch
  } = props;

  const dateFormat = "DD/MM/YYYY";

  const onSearchFunction = (e, name) => {
    onBlur(e, name, form);
  };

  const validRegex = (value, pattern) => {
    const trimmedValue = value ? value.replace(/\s/g, "") : "";
    const regex = new RegExp(pattern);
    return regex.test(trimmedValue);
  };

  function validateCleNSS(nss) {
    nss = nss?.replace("2A", "19")?.replace("2B", "18");
    let numericNIR = parseInt(nss.slice(0, -2), 10);
    let nirKey = 97 - (numericNIR % 97);
    let providedKey = parseInt(nss.slice(-2), 10);
    return nirKey === providedKey;
  }

  function isTrueSS(value, civility, dateNaissance) {

    const Civi = civility;

    var Civilité = "";
    if (Civi === "Mr") Civilité = "1";
    else Civilité = "2";

    var year = dayjs(dateNaissance)?.format("YY")

    var month = dayjs(dateNaissance)?.format("MM");
    if (
      value?.replace(/ /g, "").substring(0, 1) === Civilité &&
      value?.replace(/ /g, "").substring(1, 3) === year &&
      value?.replace(/ /g, "").substring(3, 5) === month &&
      validateCleNSS(value?.replace(/ /g, ""))
    ) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <Col className="starCol" lg={collg} md={colmd} sm={colsm} xs={colxs}>
      {inputType == "input" ? (
        <div>
          <Form.Item
            {...restField}
            name={name}
            rules={[
              {
                required: required,
                message: "",
              },
              {
                validator: (_, value) => {
                  const emptyValue = value ? value.replace(/\s/g, "") : "";
                  if (!emptyValue && required) {
                    return Promise.reject(new Error(messageRemplissage));
                  }
                  if (value) {
                    if (!validRegex(value, pattern)) {
                      return Promise.reject(new Error(messageVerification));
                    } else if (
                      minVal &&
                      maxVal &&
                      (value < minVal || value > maxVal)
                    ) {
                      return Promise.reject(
                        new Error("Veuillez vérifier ce champ")
                      );
                    } else if ((minDate && value < minDate) || (maxDate && value > maxDate)) {
                      if (
                        ["date_naissance", "date_naissance_conjoint"].includes(name)
                      ) {
                        return Promise.reject(
                          new Error(
                            "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
                          )
                        );
                      } else {
                        return Promise.reject(
                          new Error(
                            "Veuillez noter que l'âge de l'enfant assuré doit être compris entre 12 et 18 ans pour souscrire à cette offre."
                          )
                        );
                      }
                    }
                  }
                  if (form) {
                    const fields_value = form.getFieldsValue()
                    if (name === "num_sec_sociale" && isTrueSS(value, fields_value["civilite"], fields_value["date_naissance"]) === false) {
                      return Promise.reject(new Error(messageVerification));
                    }
                    if (name === "num_sec_sociale_conjoint" && isTrueSS(value, fields_value["civilteConjoint"], fields_value["date_naissance_conjoint"]) === false) {
                      return Promise.reject(new Error(messageVerification));
                    }
                    if (name[1] === "num_sec_sociale") {
                      const civility = fields_value["tab_enfants"][name[0]]?.civilité_enfant
                      const date_naissance = fields_value["tab_enfants"][name[0]]?.date_naissance_enfant
                      const parents_secu = [fields_value["num_sec_sociale"], fields_value["num_sec_sociale_conjoint"]]
                      const today = moment()
                      const moment_dn = moment(date_naissance.format("YYYY-MM-DD"))
                      const child_age = today.diff(moment_dn, "year")
                      const isSSValid = isTrueSS(value, civility, date_naissance);
                      const isParentSS = parents_secu.includes(value);
                      const isChildAt18 = child_age >= 18;

                      if ((!isSSValid && !isParentSS) || (!isSSValid && isChildAt18)) {
                        return Promise.reject(new Error(messageVerification));
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            className="star"
            label={<label className={className}>{label}</label>}
          >
            <Input
              disabled={name === "pays" || name === "dateEffet"}
              max={maxDate}
              min={minDate}
              onBlur={(e) => {
                if (onBlur) onSearchFunction(e.target.value, name);
              }}
              addonBefore={addonBefore}
              addonAfter={addonAfter}
              placeholder={placeholder}
              value={defaultValue}
              prefix={prefix}
              controls={controls}
              type={type}
              onChange={onchange}
              maxLength={maxLength}
              style={{
                backgroundColor:
                  name === "dateEffet"
                    ? "#F2FAFF"
                    : name === "pays"
                      ? "#F2F3F4"
                      : "white",
                border:
                  name === "dateEffet"
                    ? "2px solid #D2EEFF"
                    : name === "pays"
                      ? " 2px solid #E7E7E7"
                      : "",
                textAlign: name === "dateEffet" ? "center" : "start",
                color:
                  name === "dateEffet"
                    ? "#000"
                    : name === "pays"
                      ? "#C8C8C8"
                      : "#000",
              }}
            />
          </Form.Item>
        </div>
      ) : inputType === "phone" ? (
        <Form.Item
          name="inputphone"
          rules={[
            {
              required: required,
              message: "",
            },
          ]}
          label={<label className={className}>{label}</label>}
        >
          <Space.Compact compact="true" style={{ display: "flex" }}>
            <Form.Item
              name={namePrefix}
              style={{ flex: 1 }}
              initialValue={defaultValue}
              rules={[
                {
                  required: required,
                  message: "",
                },

                {
                  validator: (_, value) => {
                    const emptyValue = value ? value.replace(/\s/g, "") : "";
                    if (!emptyValue) {
                      return Promise.reject(new Error(messageRemplissage));
                    }

                    if (!validRegex(value, /^\+\d+$/)) {
                      return Promise.reject(new Error(messageVerification));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                style={{ borderRight: "white" }}
                onChange={handlePrefixChange}
              />
            </Form.Item>
            <Form.Item
              name={name}
              rules={[
                {
                  required: required,
                  message: "",
                },

                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    const emptyValue = value ? value.replace(/\s/g, "") : "";
                    if (!emptyValue) {
                      return Promise.reject(new Error(messageRemplissage));
                    }
                    if (
                      !validRegex(
                        value,
                        getFieldValue(namePrefix).replace(/\s/g, "") === "+33" || getFieldValue(namePrefix).replace(/\s/g, "") === "0033"
                          ? pattern
                          : /^[0-9]*$/
                      )
                    ) {
                      return Promise.reject(new Error(messageVerification));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{ flex: 4 }}
            >
              <Input addonAfter={addonAfter} onChange={onchange} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      ) : inputType === "select" ? (
        <Form.Item
          name={name}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
          label={<label className={className}>{label}</label>}
        >
          <Select
            showSearch={showSearch}
            onSelect={onSelect}
            options={options && options.length !== 0 ? options : ""}
            placeholder={placeholder}
          />
        </Form.Item>
      ) : inputType === "search" ? (
        <Form.Item
          rules={rules}
          name={name}
          label={<label className={className}>{label}</label>}
        >
          <Search
            type={type}
            placeholder="exp: 1245893677"
            size="large"
            onSearch={(e) => onSearchFunction(e, name)}
          />
        </Form.Item>
      ) : inputType === "date" ? (
        <Form.Item
          label={<label className={className}>{label}</label>}
          name={name}
          rules={[
            {
              type: 'object',
              required: required,
              message: messageRemplissage,
            },
            {
              validator: (_, value) => {
                if (value) {
                  const effetDate = dayjs()
                    .add(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                  const yearEffet = dayjs(effetDate).year();
                  const birthYear = dayjs(value).year();
                  const age = yearEffet - birthYear;
                  if (age < minDate || age > maxDate) {
                    {
                      return Promise.reject(new Error(messageVerificationDate));
                    }
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
          className="star"
        >
          <DatePicker
            className={name === "dateEffet" ? classes.dateEffet : ""}
            disabled={name === "dateEffet"}
            size="large"
            placeholder="jj/mm/aaaa"
            format={dateFormat}
            style={{
              width: "100%",
              height: "100%",
            }}
          // onChange={onchange}
          // value={value}
          />
        </Form.Item>
      ) : inputType === "checkbox" ? (
        <Form.Item
          name={name}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
          label={<label className={classes.labelStyle}>{label}</label>}
        >
          <Checkbox.Group>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100vh",
              }}
            >
              {checkboxValues?.map((checkboxValue, index) => (
                <Col key={index}>
                  <Checkbox
                    id={checkboxValue}
                    value={checkboxValue}
                    className={classes.checkboxText}
                  >
                    {checkboxValue}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      ) : (
        <Form.Item
          name={name}
          label={<label>{label}</label>}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
        >
          <Radio.Group className={classes.rowFlex}>
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {radioValues?.map((value, index) => (
                <Col key={index}>
                  {value.card ? (
                    <CardsComponent
                      image={value.cardImage}
                      name={value.name}
                      form={form}
                    />
                  ) : null}
                  <Radio
                    value={value.name}
                    key={index}
                    onChange={() => handleCardClick(value.name)}
                    className={classes.labelStyle}
                  >
                    {value.name}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      )}
    </Col>
  );
};

export default InputComponent;
