import React, { useContext, useEffect, useState } from "react";
import {
  Blessure,
  Hospitalisation,
  PackGaranties,
} from "../../components/Icons/Icons";
import CollapsibleCard from "./CollapsibleCard/CollapsibleCard";
import { Row, Col } from "antd";
import styles from "./Tarification.module.css";
import FormHeader from "../../components/FormHeader/FormHeader";
import GlobalContext from "../../contexts/GlobalContext";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

const Tarification = () => {
  const { garantie, globalData, setIdOpp } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false)
  const blessureoDetails = [
    {
      title:
        "Versement d’une indemnité forfaitaire journalière en cas d’hospitalisation : versement d’indemnités journalières, dont le montant est choisi lors de l’adhésion, en cas de séjour d’au moins 24 heures consécutives dans un établissement hospitalier (hôpital ou clinique, public ou privé).",
    },
    {
      title:
        "Exonération des cotisations : en cas d’hospitalisation de l’adhérent supérieure à 30 jours, l’adhérent est exonéré du paiement des cotisations.",
    },
    {
      title:
        "Versement d’un capital forfaitaire à l’assuré victime de blessure(s) résultant d’un accident : Fractures, Brûlures, Amputations, Blessures à l’œil.",
    },
  ];
  const hospineoDetails = [
    {
      title:
        " Indemnités journalières en cas d’hospitalisation : versement d’indemnités journalières, dont le montant est choisi lors de l’adhésion, en cas de séjour d’au moins 24 heures consécutives dans un établissement hospitalier (hôpital ou clinique, public ou privé). Le montant de l’indemnité est doublé en cas d’hospitalisation consécutive à un accident.",
    },
    {
      title:
        "Exonération des cotisations : l’adhérent est exonéré du paiement des cotisations du contrat s’il fait l’objet d’une hospitalisation supérieure à 30 jours. La prise en charge débute à l’issue de l’expiration de la franchise de 30 jours et cesse dès que l’indemnité journalière cesse d’être versée.",
    },
  ];
  const relaxeoDetails = [
    {
      title:
        "Versement d’une indemnité forfaitaire journalière en cas d’hospitalisation : versement d’indemnités journalières, dont le montant est choisi lors de l’adhésion, en cas de séjour d’au moins 24 heures consécutives dans un établissement hospitalier (hôpital ou clinique, public ou privé).",
    },
    {
      title:
        "Exonération des cotisations : en cas d’hospitalisation de l’adhérent supérieure à 30 jours, l’adhérent est exonéré du paiement des cotisations.",
    },
    {
      title:
        "Décès consécutif à un accident : versement au(x) bénéficiaire(s) désigné(s) du capital de base garanti choisi par l’adhérent.",
    },
    {
      title:
        "Décès consécutif à un accident de la circulation : versement au(x) bénéficiaire(s) désigné(s) du double du capital de base garanti choisi par l’adhérent.",
    },
    {
      title:
        "Décès consécutif à un accident en tant que passager d’un moyen de transport en commun : versement au(x) bénéficiaire(s) désigné(s) du 2,5 fois du capital garanti choisi par l’adhérent.",
    },
    {
      title:
        "Perte Totale et Irréversible d’Autonomie (PTIA) consécutive à un accident : en cas de perte totale et irréversible d’autonomie, versement à l’assuré du capital de base garanti choisi par l’adhérent.",
    },
    {
      title:
        "Versement d’un capital forfaitaire à l’assuré victime de blessure(s) résultant d’un accident : Fractures, Brûlures, Amputations, Blessures à l’œil.",
    },
  ];
  const tarifications = [
    {
      typeGarantie: "BLESSUREO",
      tarif: "12,75 €",
      cardIcon: <Blessure width="4rem" height="4rem" />,
      details: blessureoDetails,
      firstWarningTitle: "",
      firstWarningContent:
        "Un capital forfaitaire vous est versé selon le type de blessure, dès sa constatation médicale, pouvant aller de 1 250 € à 20 000 €.",
      additionalInfo: "",
    },
    {
      typeGarantie: "HOSPINEO",
      tarif: "12,75 €",
      cardIcon: <Hospitalisation width="4rem" height="4rem" />,
      details: hospineoDetails,
      firstWarningTitle: "",
      firstWarningContent:
        "Une couverture de 40 €/jour dans la limite de 90 jours pour un même événement, avec une franchise de 3 jours",
      additionalInfo: "",
    },
    {
      typeGarantie: "PAISIBLEO",
      tarif: "5,32 €",
      cardIcon: <PackGaranties width="4rem" height="4rem" />,
      details: relaxeoDetails,
      firstWarningTitle: "DÉCÈS PAR ACCIDENT ",
      firstWarningContent:
        "Capital forfaitaire versé aux bénéficiaires désignés par l'adhérent",
      secondWarningTitle:
        "INVALIDITÉ ABSOLUE ET DÉFINITIVE (PTIA) SUITE À ACCIDENT ",
      secondWarningContent: "Capital forfaitaire versé à l'adhérent du contrat",
      additionalInfo:
        "Si le décès ou l'état de PTIA est consécutif à un accident dela circulation et que l'indemnisation est due, le capital est doublé.",
      beneficiary: true,
    },
  ];

  useEffect(() => {
    let info_specifiques_campagnes = { budget: "" };
    let info_specifiques_ligne_produit = {
      Ro: null,
      Ro_conjoint: null,
      annee_naissance: globalData?.date_naissance
        ? dayjs(globalData?.date_naissance, "DD/MM/YYYY").format("YYYY-MM-DD")
        : null,
      annee_naissance_conjoint:
        globalData?.assurer === "Moi et mes proches" &&
        globalData?.date_naissance_conjoint
          ? dayjs(globalData?.date_naissance_conjoint, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            )
          : null,
      annee_naissance_enfant1:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[0]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[0]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      annee_naissance_enfant2:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[1]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[1]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      annee_naissance_enfant3:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[2]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[2]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      annee_naissance_enfant4:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[3]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[3]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      annee_naissance_enfant5:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[4]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[4]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      annee_naissance_enfant6:
        globalData?.tab_enfants &&
        globalData?.tab_enfants[5]?.date_naissance_enfant
          ? dayjs(
              globalData?.tab_enfants[5]?.date_naissance_enfant,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY")
          : null,
      ro_enfant1: null,
      ro_enfant2: null,
      ro_enfant3: null,
      ro_enfant4: null,
      ro_enfant5: null,
      ro_enfant6: null,
      situation_pro: null,
      NumeroSecuriteSocial: null,
      NumeroSecuriteSocialConjoint: null,
      NumeroSecuriteSocialEnfant1: null,
      NumeroSecuriteSocialEnfant2: null,
      NumeroSecuriteSocialEnfant3: null,
      NumeroSecuriteSocialEnfant4: null,
      NumeroSecuriteSocialEnfant5: null,
      eSignRef: null,
      YOUSIGN_URL: "",
    };
    var tab = [];
    if (globalData?.assurer === "Moi et mes proches") {
      tab = [
        ...tab,
        {
          name: globalData?.nom_conjoint,
          surname: globalData?.prenom_conjoint,
          DN: globalData?.date_naissance_conjoint
            ? dayjs(globalData?.date_naissance_conjoint, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            : "",
          form: {
            data: {
              paysNaissance: globalData?.lieu_naissance_conjoint,
              ro:globalData?.regime_obligatoire_conjoint,
              sec:globalData?.num_sec_sociale_conjoint,
            },
          },
          id: "",
          id_opp: "",
          subs_tag: "Conjoint",
          campagne_id: "",
          post: "",
          interlocuteur: "",
          ville: globalData?._ville,
          situation: "",
          users_nom: "",
          users_prenom: "",
          adresse_mail: globalData?.email_conjoint,
          civilite: globalData?.civilteConjoint,
          prospects_civ: "",

          prospects_situation: "",
          nb_enfants: "",
          adresses_adresse: "",
          streetNumber: "",
          CP: globalData?._code_postal,
          streetName: "",
          adresses_ville: "",
          complement_adr: "",
          tel: globalData?.telephone_conjoint,
          tel2: "",
          mobile: "",
          first_campagne: "",
          description: "",
          etat: "",
          lat: "",
          latitude: "",
          longitude: "",
          commercieux_id: "",
          commentaire: "",
          geom: "",
          tags: [],
          lng: "",
          date_naiss: "",
          collab: "",
          id_pros: "",
          id_camp: "",
          coment_plus: "",
          code: "",
          rue: "",
          numero: "",
          complement: "",
          mail: globalData?.email_conjoint,
          nb_enfant: "",
          infcomp: "",
          relation: "Conjoint",
          pays: globalData?.pays,
          invalidForms: [],
          deleted: false,
          information_bancaire: {
            id: "",
            titulaire_compte: "",
            iban: "",
            bic_swift: "",
            type_iban: "",
            nom_banque: "",
          },
          prospects_id: "",
        },
      ];
    }
    if (globalData?.tab_enfants?.length >= 1) {
      globalData?.tab_enfants?.forEach((element, index) => {
        tab = [
          ...tab,
          {
            name: element?.nom_enfant,
            surname: element?.prenom_enfant,
            DN: element?.date_naissance_enfant
              ? dayjs(element?.date_naissance_enfant, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
              : "",
            form: {
              data: {
                paysNaissance: element?.lieu_naissance_enfant,
                ro:element?.regime_obligatoire,
                sec:element?.num_sec_sociale,
              },
            },
            id: "",
            id_opp: "",
            subs_tag: `Enfant${index + 1}`,
            campagne_id: "",
            post: "",
            interlocuteur: "",
            ville: globalData?._ville,
            situation: "",
            users_nom: "",
            users_prenom: "",
            adresse_mail: "",
            civilite: element?.civilité_enfant,
            prospects_civ: "",

            prospects_situation: "",
            nb_enfants: "",
            adresses_adresse: "",
            streetNumber: "",
            CP: globalData?._code_postal,
            streetName: "",
            adresses_ville: "",
            complement_adr: "",
            tel: "",
            tel2: "",
            mobile: "",
            first_campagne: "",
            description: "",
            etat: "",
            lat: "",
            latitude: "",
            longitude: "",
            commercieux_id: "",
            commentaire: "",
            geom: "",
            tags: [],
            lng: "",
            date_naiss: "",
            collab: "",
            id_pros: "",
            id_camp: "",
            coment_plus: "",
            code: "",
            rue: "",
            numero: "",
            complement: "",
            mail: "",
            nb_enfant: "",
            infcomp: "",
            relation: "Descendant",
            pays: globalData?.pays,
            invalidForms: [],
            deleted: false,
            information_bancaire: {
              id: "",
              titulaire_compte: "",
              iban: "",
              bic_swift: "",
              type_iban: "",
              nom_banque: "",
            },
            prospects_id: "",
          },
        ];
      });
    }

    let offre = {
      info_specifiques_campagnes: info_specifiques_campagnes,
      info_specifiques_ligne_produit: info_specifiques_ligne_produit,
      prospects: {
        listProspects: tab,
        mainProspect: {
          form: {
            data: {
              paysNaissance: globalData?.lieuNaissance,
              ro:globalData?.regime_obligatoire,
              sec:globalData?.num_sec_sociale,
            },
          },
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: globalData?._ville,
          situation: null,
          adresse_mail: globalData?.souscripteur_email,
          civilite: globalData?.civilite,
          name: globalData?.nom,
          surname: globalData?.prenom,
          DN: dayjs(globalData?.date_naissance, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          nb_enfants: globalData?.tab_enfants?.length,
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: globalData?._code_postal,
          streetName: `${globalData["_adresse"]}, France`,
          complement_adr: null,
          tel: globalData?.téléphone,
          tel2: null,
          mobile: null,
          description: null,
          relation: "principal",
          subs_tag: "Prospect",
        },
      },
    };
    setIsLoading(true)
    axios
      .post(`${import.meta.env.VITE_API_URL_AS}offre/add`, offre, {
        headers: {
          idSession: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setIdOpp(res?.data?.id_opportunite_md5);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      });
  }, []);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div style={{ width: "100%" }}>
        {isLoading && <CustomLoader transparent="true" />}
      <div className={styles.titleContainer}>
        <FormHeader name={"Un tarif unique sans sélection médicale !"} />
        <div className={styles.tarif}>
          {garantie?.tarif?.toFixed(2)} €
          <span className={styles.subscriptText}>/par mois</span>
        </div>
      </div>
      {garantie?.name === "PAISIBLEO" ? (
        <Row justify="center">
          <Col
            key={tarifications[2].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[2].typeGarantie}
              tarif={tarifications[2].tarif}
              cardIcon={tarifications[2].cardIcon}
              details={tarifications[2].details}
              firstWarningTitle={tarifications[2].firstWarningTitle}
              firstWarningContent={tarifications[2].firstWarningContent}
              secondWarningTitle={tarifications[2].secondWarningTitle}
              secondWarningContent={tarifications[2].secondWarningContent}
              additionalInfo={tarifications[2].additionalInfo}
            />
          </Col>
        </Row>
      ) : garantie?.name === "BLESSUREO" ? (
        <Row justify="center">
          <Col
            key={tarifications[0].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[0].typeGarantie}
              tarif={tarifications[0].tarif}
              cardIcon={tarifications[0].cardIcon}
              details={tarifications[0].details}
              firstWarningTitle={tarifications[0].firstWarningTitle}
              firstWarningContent={tarifications[0].firstWarningContent}
              secondWarningTitle={tarifications[0].secondWarningTitle}
              secondWarningContent={tarifications[0].secondWarningContent}
              additionalInfo={tarifications[0].additionalInfo}
            />
          </Col>
        </Row>
      ) : garantie?.name === "HOSPINEO" ? (
        <Row justify="center">
          <Col
            key={tarifications[1].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[1].typeGarantie}
              tarif={tarifications[1].tarif}
              cardIcon={tarifications[1].cardIcon}
              details={tarifications[1].details}
              firstWarningTitle={tarifications[1].firstWarningTitle}
              firstWarningContent={tarifications[1].firstWarningContent}
              secondWarningTitle={tarifications[1].secondWarningTitle}
              secondWarningContent={tarifications[1].secondWarningContent}
              additionalInfo={tarifications[1].additionalInfo}
            />
          </Col>
        </Row>
      ) : null}

      {/* <ApplicationFeeInput firstMonthlyPayment={40} applicationFee={35} /> */}
    </div>
  );
};

export default Tarification;
