import React, { useEffect } from "react";
import classes from "./ChildDetails.module.css";
import { Col, Collapse, Row } from "antd";
import InputComponent from "../../../components/InputComponent/InputComponent";
import listPays from "../../../constants/listPays.json";
import { IconRemoveChild } from "../../../components/Icons/Icons";
const { Panel } = Collapse;

function ChildDetails(props) {
  const { remove, name, restField, form, optionsRegime } = props;
  const pays_options = Object.entries(listPays)
    .map(([code, name]) => ({
      value: name,
      key: name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
  return (
    <Col lg={12}>
      <Collapse
        bordered={false}
        type="ghost"
        expandIconPosition="end"
        className={classes.container}
        defaultActiveKey={["1"]}
      >
        <Panel
          header={
            <div className={classes.headerStyle}>
              <span>
                Enfant
                <span style={{ marginLeft: "0.5rem" }}>{name + 1}</span>
              </span>
              <div
                id={`BTN_supprimer_enfant_${name}`}
                onClick={() => {
                  remove(name);
                }}
              >
                {" "}
                <IconRemoveChild />
              </div>
            </div>
          }
          key="1"
          className={classes.panelStyle}
        >
          <Row>
            <InputComponent
              name={[name, `civilité_enfant`]}
              messageRemplissage="Veuillez remplir ce champ."
              label={`Civilité enfant ${name + 1}`}
              inputType="select"
              required={true}
              options={[
                {
                  value: "Mme",
                  label: "Mme.",
                },
                {
                  value: "Mr",
                  label: "M.",
                },
              ]}
              collg={24}
              colMd={24}
              colxs={24}
              className={classes.label}
              restField={restField}
            />
            <InputComponent
              name={[name, `nom_enfant`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              pattern={
                new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
              }
              messageVerification="Veuillez vérifier ce champ."
              label={`Nom enfant ${name + 1}`}
              size="large"
              inputType="input"
              className={classes.label}
              collg={24}
              colMd={24}
              colxs={24}
              restField={restField}
            />
            <InputComponent
              name={[name, `prenom_enfant`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              pattern={
                new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
              }
              messageVerification="Veuillez vérifier ce champ."
              label={`Prénom enfant ${name + 1}`}
              size="large"
              inputType="input"
              className={classes.label}
              collg={24}
              colMd={24}
              colxs={24}
              restField={restField}
            />
            <InputComponent
              name={[name, `lieu_naissance_enfant`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              label={`Pays de naissance enfant ${name + 1}`}
              size="large"
              className={classes.label}
              inputType="select"
              collg={24}
              colMd={24}
              colxs={24}
              restField={restField}
              showSearch={true}
              options={pays_options}
            />
            <InputComponent
              name={[name, `date_naissance_enfant`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              label={`Date de naissance enfant ${name + 1}`}
              size="large"
              className={classes.label}
              messageVerificationDate={
                "Veuillez noter que l'âge de l'enfant assuré doit être compris entre 12 et 18 ans pour souscrire à cette offre."
              }
              inputType="date"
              collg={24}
              colMd={24}
              colxs={24}
              restField={restField}
              minDate={12}
              maxDate={18}
            />
            <InputComponent
              form={form}
              name={[name, `num_sec_sociale`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              messageVerification="Veuillez vérifier ce champ."
              label={`N° sécurité sociale enfant ${name + 1}`}
              size="large"
              inputType="input"
              className={classes.label}
              collg={24}
              colMd={24}
              colxs={24}
            />
            <InputComponent
              name={[name, `regime_obligatoire`]}
              label={`Régime obligatoire enfant ${name + 1}`}
              messageRemplissage="Veuillez remplir ce champ."
              required="true"
              inputType="select"
              options={optionsRegime}
              collg={24}
              colMd={24}
              colxs={24}
              className={classes.label}
            />


            {/* <InputComponent
              name={[name, `date_naissance_enfant`]}
              required={true}
              messageRemplissage="Veuillez remplir ce champ."
              label={`Date de naissance enfant ${name + 1}`}
              size="large"
              className={classes.label}
              inputType="input"
              type="date"
              collg={24}
              colMd={24}
              colxs={24}
              restField={restField}
              minDate={`${getYears(18)}-01-01`}
              maxDate={`${getYears(12)}-12-31`}
     
            /> */}
          </Row>
        </Panel>
      </Collapse>
    </Col>
  );
}

export default ChildDetails;
