import React, { useContext, useEffect } from "react";
import Card from "./Card/Card";
import { Checkbox, Col, Row, notification } from "antd";
import classes from "./InformationsComplementaires.module.css";
import FormHeader from "../../components/FormHeader/FormHeader";
import GlobalContext from "../../contexts/GlobalContext";
import InputComponent from "../../components/InputComponent/InputComponent";

function InformationsComplementaires(props) {
  const { globalData, garantieOptions, setGarantieOptions, form } =
    useContext(GlobalContext);

  const cardGaranties = [
    {
      name: "conséquences_accidents_corporels",
      text: "Souhaitez-vous vous garantir contre les conséquences d’accidents corporels ?",
    },
    {
      name: "accidents_corporels_conséquences_graves",
      text: "Souhaitez-vous vous garantir vous-même et vos proches contre les accidents corporels aux conséquences les plus graves (invalidité absolue et définitive–décès par accident) ?",
    },

    {
      name: "indemnité_journalière_cas_hospitalisation",
      text: "Souhaitez-vous être garanti par une indemnité journalière en cas d’hospitalisation à la suite d’une maladie ou d’un accident ?",
    },
    {
      name: "versement_capital_forfaitaire",
      text: "Souhaitez-vous être garanti par le versement d’un capital forfaitaire notamment en cas de fractures (col du fémur, fractures des membres), brûlures, et blessures aux yeux ?",
    },
    {
      name: "subi_accident_cinq_dernières_années",
      text: "Avez-vous déjà subi un accident durant les 5 dernières années ? (1)",
    },
    {
      name: "hospitalisation_programmée",
      text: "Avez-vous une hospitalisation programmée ? (2)",
    },
    {
      name: "mesure_curatelle_tutelle",
      text: "Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle ?",
    },
    {
      name: "papiers_sans_aide_ext",
      text: "Est-ce qu'une aide extérieure vous aide à gérer vos papiers ?",
    },
    {
      name: "politiquement_exposée",
      text: "Êtes-vous une personne politiquement exposée ?",
    },
  ];
  const cardGarantiesConjoint = [
    {
      name: "conséquences_accidents_corporels",
      text: "Souhaitez-vous garantir votre conjoint contre les conséquences d'accidents corporels ?",
    },
    {
      name: "accidents_corporels_conséquences_graves",
      text: "Souhaitez-vous garantir à la fois votre conjoint et vos proches contre les accidents corporels aux conséquences les plus graves (invalidité absolue et définitive–décès par accident) ?",
    },

    {
      name: "indemnité_journalière_cas_hospitalisation",
      text: "Souhaitez-vous que votre conjoint soit garanti par une indemnité journalière en cas d'hospitalisation à la suite d'une maladie ou d'un accident ?",
    },
    {
      name: "versement_capital_forfaitaire",
      text: "Souhaitez-vous que votre conjoint soit garanti par le versement d'un capital forfaitaire notamment en cas de fractures (col du fémur, fractures des membres), brûlures, et blessures aux yeux ?",
    },
    {
      name: "subi_accident_cinq_dernières_années",
      text: "Votre conjoint a-t-il déjà subi un accident durant les 5 dernières années ? (1)",
    },
    {
      name: "hospitalisation_programmée",
      text: "Votre conjoint a-t-il une hospitalisation programmée ? (2)",
    },
  ];

  const cardGarantiesEnfant = [
    {
      name: "conséquences_accidents_corporels",
      text: "Souhaitez-vous garantir votre enfant contre les conséquences d'accidents corporels ?",
    },
    {
      name: "accidents_corporels_conséquences_graves",
      text: "Souhaitez-vous garantir votre enfant, ainsi que vos proches, contre les accidents corporels aux conséquences les plus graves (invalidité absolue et définitive, décès par accident) ?",
    },

    {
      name: "indemnité_journalière_cas_hospitalisation",
      text: "Souhaitez-vous que votre enfant soit couvert par une indemnité journalière en cas d'hospitalisation suite à une maladie ou un accident ?",
    },
    {
      name: "versement_capital_forfaitaire",
      text: "Souhaitez-vous que votre enfant soit couvert par le versement d'un capital forfaitaire, notamment en cas de fractures (col du fémur, fractures des membres), brûlures, et blessures aux yeux ?",
    },
    {
      name: "subi_accident_cinq_dernières_années",
      text: "Votre enfant a-t-il déjà subi un accident durant les 5 dernières années ? (1)",
    },
    {
      name: "hospitalisation_programmée",
      text: "Votre enfant a-t-il une hospitalisation programmée ? (2)",
    },
  ];
  const checkboxValues = ["Par téléphone", "Par Email", "Par courrier"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if ( garantieOptions["mesure_curatelle_tutelle"] === true &&
    garantieOptions["politiquement_exposée"] === false) {
      notification.destroy();
      notification.warning({
        className: classes.warning,
        message: "Attention",
        description:
          "Non-éligibilité en raison d'une mesure de Curatelle ou de Tutelle.",
      });
    } else if (garantieOptions["politiquement_exposée"] === true &&
    garantieOptions["mesure_curatelle_tutelle"] === false) {
      notification.destroy();
      notification.warning({
        className: classes.warning,
        message: "Attention",
        description:
          "Nous regrettons de vous informer que nous ne pouvons pas assurer les personnes politiquement exposées (PPE) pour le moment.",
      });
    }
    else if(garantieOptions["mesure_curatelle_tutelle"] === true &&
    garantieOptions["politiquement_exposée"] === true){
      notification.destroy();
      notification.warning({
        className: classes.warning,
        message: "Attention",
        description:
          "Non-éligibilité en raison d'une mesure de Curatelle ou de Tutelle.",
      });
      setTimeout(() => {     notification.warning({
        className: classes.warning,
        message: "Attention",
        description:
          "Nous regrettons de vous informer que nous ne pouvons pas assurer les personnes politiquement exposées (PPE) pour le moment.",
      });
      }, 400);
 
    }
    else {
      notification.destroy();
    }
  }, [garantieOptions["politiquement_exposée"] , garantieOptions["mesure_curatelle_tutelle"]]);
  return (
    <div>
      <FormHeader name="Informations complémentaires" />

      <div>
        <Row gutter={24} className={classes.rowCard}>
          {cardGaranties?.map((item, index) => (
            <Card
              key={index}
              collg={8}
              colMd={24}
              colxs={24}
              name={item.name}
              text={item.text}
              garantieOptions={garantieOptions}
              setGarantieOptions={setGarantieOptions}
            />
          ))}
        </Row>
      </div>
      {globalData["assurer"] === "Moi et mes proches" && (
        <>
          <FormHeader name="Informations complémentaires Conjoint" />
          <Row gutter={24} className={classes.rowCard}>
            {cardGarantiesConjoint?.map((item, index) => (
              <Card
                key={index}
                collg={8}
                colMd={24}
                colxs={24}
                name={`${item.name}_conjoint`}
                text={item.text}
                garantieOptions={garantieOptions}
                setGarantieOptions={setGarantieOptions}
              />
            ))}
          </Row>{" "}
        </>
      )}

      {globalData?.tab_enfants?.length >= 1 && (
        <>
          <FormHeader name="Informations complémentaires Enfant" />
          <Row gutter={24} className={classes.rowCard}>
            {cardGarantiesEnfant?.map((item, index) => (
              <Card
                key={index}
                collg={8}
                colMd={24}
                colxs={24}
                name={`${item.name}_enfant`}
                text={item.text}
                garantieOptions={garantieOptions}
                setGarantieOptions={setGarantieOptions}
              />
            ))}
          </Row>{" "}
        </>
      )}
      <Row className={classes.cardContainer}>
        <Col
          className={classes.cardContent}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          J’accepte de recevoir des offres commerciales de la part du courtier
          et marques déposées de bénéficier de tarifs et avantages intéressant
          sans engagement de ma part.
        </Col>
        <InputComponent
          lgCol={24}
          xsCol={24}
          mdCol={12}
          inputType="checkbox"
          name="type_envoi"
          form={form}
          checkboxValues={checkboxValues}
        />
      </Row>
      <Row gutter={24}>
        <p className={classes.text}>
          (1) Nous vous informons que ne donne pas lieu à indemnisation tout
          événement antérieur à la date prise d’effet du contrat.{" "}
        </p>
        <p className={classes.text}>
          (2) Nous vous informons que les hospitalisations ne sont pas
          indemnisées pour tout événement antérieur à la date prise d’effet du
          contrat, et que le risque de maladie pour la garantie IJH ne prend
          effet que 90 jours francs après cette date.
        </p>
      </Row>
    </div>
  );
}

export default InformationsComplementaires;
