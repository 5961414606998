import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { version } from "../package.json"
import { Layout } from 'antd';
const { Footer } = Layout;
import AppContext from "./contexts/AppContext";
import NotFound from "./components/NotFound/NotFound";
import Home from "./pages/Home/Home";
import "dayjs/locale/fr";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
dayjs.locale("fr");
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },

]);
if (import.meta.env.VITE_API_ENVIRONEMENT === 'prod') {
  Sentry.init({
    dsn: "https://ebc6e603176cd35180ca49bd60dafdf6@sentry.neopolis-dev.com/68",
  });
}


ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <AppContext>
    {/* <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#DB4537",
        },
        components: {
          Switch: {
            colorPrimary: "#024FA8",
            colorPrimaryHover: "#024FA8",
            colorTextQuaternary: "#A4A4A4",
            lineHeight: 2,
          },
          Radio: {
            colorPrimary: "#024FA8",
          },
          Select: {
            lineWidth: 1,
            controlHeight: 47,
          },
          Checkbox: {
            colorPrimary: "#024FA8",
            colorPrimaryHover: "#024FA8",
            controlInteractiveSize: 22,
          },
          Modal: {
            colorBgMask: "rgba(2, 79, 168, 0.4)",
          },
        },
      }}
    > */}
    <RouterProvider router={router} />
    {/* </ConfigProvider> */}
    <Footer style={{
      textAlign: 'center',
    }} > V {version}  {'- ' + import.meta.env.VITE_API_TAG} </Footer>

  </AppContext>
  // </React.StrictMode>
);
