import React from 'react';
import svg from '../../assets/notfound.svg';
import Logo from '../../assets/logoprev.png';
import { Typography } from 'antd';
import classes from './NotFound.module.css';
function NotFound() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Typography.Text style={{ color: 'white' }}>
            Parcours Prévoyance
          </Typography.Text>
        </div>
        <div className={classes.topBar}>
          <img
            src={Logo}
            alt='logo AsSolutions'
            className={classes.logoStyle}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '70%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={svg}
          alt='404'
          style={{ width: '100%', height: '100%' }}
          height={925}
          width={925}
        />{' '}
      </div>
    </div>
  );
}

export default NotFound;
