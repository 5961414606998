import { Button, Col, Modal, Row } from "antd";
import React, { useContext } from "react";
import { Felicitations } from "../../../components/Icons/Icons";
import classes from "./ModalSuccess.module.css";
import GlobalContext from "../../../contexts/GlobalContext";
const ModalSuccess = (props) => {
  const { open } = props;
  const { globalData } = useContext(GlobalContext);
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const partnership_id = searchParams?.get("partnership_id");

  return (
    <Modal open={open} className={classes.ModalSuccess} closeIcon={false}>
      <Row className={classes.modalRow}>
        <Col className={classes.Felicitations}>
          <span>
            Félicitations! {globalData ? globalData["civilite"] : ""}{" "}
            {globalData ? globalData["nom"] + " "+ globalData['prenom'] : ""}{" "}
          </span>
        </Col>
        <Col className={classes.souscription}>
          <span>Vous avez souscrit le contrat Prévoyance Prévoir.  </span>
        </Col>
        <Col>
          <Felicitations />
        </Col>
        <Col className={classes.emailContrat}>
          <span>Vous allez recevoir votre contrat d’assurance par e-mail.</span>
        </Col>
        <Col className={classes.remerciement}>
          <span>Prévoir vous remercie de votre confiance. </span>
        </Col>
        <Col>
          <Button
            className={classes.nouveauSousc}
            onClick={() => {
              window.location = `/?partnership_id=${partnership_id}`;
              sessionStorage.clear();
            }}
            id="BTN_souscrire_nouveau_contrat"
          >
            Souscrire un nouveau contrat{" "}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSuccess;
