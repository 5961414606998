import { Col, Row } from "antd";
import React from "react";
import styles from "./ExportDevis.module.css";
import { ExportIcon, SendIcon } from "../../../components/Icons/Icons";

const ExportDevis = (props) => {
  const { exporterDevis, sendEmail } = props;
  return (
    <Row gutter={24} className={styles.exportDevis}>
      <Col xs={24} sm={24} md={24} lg={12} className={styles.btn}>
        <button
          type="button"
          className={styles.exportButton}
          onClick={exporterDevis}
          id="BTN_exporter_devis"
        >
          <div className={styles.exportIcon}>
            <ExportIcon />
          </div>
          <span style={{
            fontSize:"1.2rem"
          }} >Exporter Devis</span>
        </button>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className={styles.btn}>
        <button className={styles.sendButton} onClick={sendEmail} id="BTN_envoyer_devis_email">
          <div className={styles.exportIcon}>
            <SendIcon />
          </div>
          <span style={{
            fontSize:"1.2rem"
          }}>Envoyer Devis par E-mail</span>
        </button>
      </Col>
    </Row>
  );
};

export default ExportDevis;
